import { Component, Inject, Renderer2 } from '@angular/core';
import { AppThemeService } from './core/services/app-theme/app-theme.service';
import { DOCUMENT } from "@angular/common";
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'dat';

  env = environment;
  public currentTheme: string = this.env.defaultTheme;
  screenWidth = 'wide';

  constructor(private appThemeService: AppThemeService, @Inject(DOCUMENT) private document: Document, private renderer: Renderer2){}
  ngOnInit(){
    this.switchTheme(this.env.defaultTheme);
    this.appThemeService.toggleScreenWidth(this.screenWidth);

    this.appThemeService.selectedTheme.subscribe(t => {
      this.currentTheme = t;
    });
  }

  switchTheme(theme) {
    this.appThemeService.switchAppTheme(this.currentTheme, theme, this.renderer);
  }

  toggleScreenWidth(event) {
    let sWidth = event.target.checked ? 'wide' : '';
    this.appThemeService.toggleScreenWidth(sWidth);
    this.screenWidth = sWidth;
  }
}
