import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guard';

const routes: Routes = [
  { path: '', loadChildren: () => import('./core/modules/main/main.module').then(m => m.MainModule), canActivate: [AuthGuard] },
  { path: 'account', loadChildren: () => import('./core/modules/security/security.module').then(m => m.SecurityModule) },
  { path: 'error', loadChildren: () => import('./core/error-pages/error-pages.module').then(m => m.ErrorPagesModule) },
  { path: '**', redirectTo: 'error/page-not-found' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
