import { Injectable, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { AuthService } from "angularx-social-login";
import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";

@Injectable({ providedIn: 'root' })
export class AuthenticationService {

    private _registerUrl = "http://localhost:8090/register";
    private _loginUrl = "http://localhost:8090/login";

    public userInfo = {
        user_full_name: '',
        user_img: ''
    };

    constructor(private http: HttpClient, private _router: Router, private authService: AuthService) {}

    signInWithGoogle(): void {
        this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
    }

    signInWithFB(): void {
        this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
    }

    logout() {
        localStorage.removeItem('datUserAuthenticated');
        localStorage.removeItem('datUserFullName');
        localStorage.removeItem('datUserImg');
        this.authService.signOut();
        this._router.navigate(['/account/login']);
    }

    registerUser(user) {
        return this.http.post<any>(this._registerUrl, user)
    }

    getUserInfo(){
        return {
            user_full_name: localStorage.getItem('datUserFullName'),
            user_img: localStorage.getItem('datUserImg')
        };
    }

    setUserInfo(user_info){
        this.userInfo = user_info;
        localStorage.setItem('datUserFullName', this.userInfo.user_full_name);
        localStorage.setItem('datUserImg', this.userInfo.user_img);
    }

    login(username: string, password: string) {
        return this.http.post<any>(this._loginUrl, {
            username: username,
            password: password
        })
    }

    getToken() {
        return localStorage.getItem('datUserAuthenticated')
    }

    loggedIn() {
        return !!localStorage.getItem('datUserAuthenticated')    
    }
    
}
