import { Injectable, Inject, EventEmitter, Output } from '@angular/core';
import { DOCUMENT } from "@angular/common";

@Injectable({
  providedIn: 'root'
})

export class AppThemeService {

  constructor(@Inject(DOCUMENT) private document: Document) { }

  appDirection = 'ltr';
  sideBarCollapsed = false;
  currentTheme = '';

  @Output() selectedTheme: EventEmitter<string> = new EventEmitter();

  switchAppTheme(current_theme, new_theme, renderer) {
    renderer.removeClass(document.body, current_theme);
    renderer.addClass(document.body, new_theme);
    this.selectedTheme.emit(new_theme);

    this.currentTheme = new_theme;

    let element: HTMLElement = document.getElementById("app-refresh-charts") as HTMLElement;
    if (element != null)
      element.click();
  }

  @Output() changeAppDirection: EventEmitter<string> = new EventEmitter();

  setAppDirection(dir) {
    const dom: any = document.querySelector('body');
    switch (dir) {
      case 'rtl':
        dom.classList.add('rtl');
        dom.classList.remove('ltr');
        break;
      case 'ltr':
        dom.classList.add('ltr');
        dom.classList.remove('rtl');
        break;
    }

    this.appDirection = dir;
    this.changeAppDirection.emit(this.appDirection);
  }

  getCurrentTheme() {
    return this.currentTheme;
  }

  @Output() change: EventEmitter<boolean> = new EventEmitter();

  toggleSideBar() {
    this.sideBarCollapsed = !this.sideBarCollapsed;
    this.change.emit(this.sideBarCollapsed);

    if (this.sideBarCollapsed) {
      this.document.body.classList.add('sidebar-toggled');
    } else {
      this.document.body.classList.remove('sidebar-toggled');
    }
  }

  toggleScreenWidth(width) {
    const dom: any = document.querySelector('body');
    switch (width) {
      case 'wide':
        dom.classList.remove('nas-boxed');
        break;
      default:
        dom.classList.add('nas-boxed');
        break;
    }
  }
}
