import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LanguageTranslationModule } from './core/modules/language-translation/language-translation.module';
import { CorePipesPipe } from './core/pipes/core-pipes.pipe';

import { AuthGuard } from './core/guard';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { TokenInterceptorService } from './core/services/token-interceptor.service';
import { HttpErrorInterceptor } from './core/services/http-error.interceptor';
import { LoaderService } from './core/services/loader.service';
import { LoaderInterceptor } from './core/services/loader-interceptor.service';

import { SocialLoginModule, AuthServiceConfig } from "angularx-social-login";
import { GoogleLoginProvider, FacebookLoginProvider } from "angularx-social-login";
import { FormsModule } from '@angular/forms';
import { ChatComponent } from './core/modules/communications/chat/chat.component';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

let config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider("298417785112-rk91tjpl8jtepvaj4neial73uba41t0e.apps.googleusercontent.com")
  },
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider("1473247192815364")
  }
]);
 
export function provideConfig() {
  return config;
}

@NgModule({
  declarations: [
    AppComponent,
    ChatComponent,
    CorePipesPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    LanguageTranslationModule,
    HttpClientModule,
    SocialLoginModule,
    FormsModule,
    NgbModule
  ],
  providers: [
    AuthGuard, 
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },
    { 
      provide: HTTP_INTERCEPTORS, 
      useClass: HttpErrorInterceptor, 
      multi: true 
    },
    LoaderService,
    { 
      provide: HTTP_INTERCEPTORS, 
      useClass: LoaderInterceptor, 
      multi: true 
    },
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
